
import { Options, Vue } from "vue-class-component";
import { ContactForm } from "./../classes/contact-form.class";
import * as db from "firebase/firestore";
@Options({
  name: "Contact",
})
export default class Contact extends Vue {
  // ...
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Contact");
  }
  goToSkillPage(): void {
    document.getElementById("contact-div")?.classList.add("fade-out-right");
    document
      .getElementById("contact-div")
      ?.addEventListener("animationend", () => {
        this.$router.push("/skills");
        this.$store.commit("setCurrentPageTitle", "Compétences");
      });
  }
  mounted(): void {
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        this.goToSkillPage();
      }
    });
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=6Lf6DbcfAAAAAB7s0J5oTa-SDih8US-BrEB0KvSl"
    );
    document.getElementById("contact-div").appendChild(recaptchaScript);
    // set box shadow wheat on focus and active on all inputs and text area and buttons
    let inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("focus", () => {
        inputs[i].classList.add("input-focus");
      });
      inputs[i].addEventListener("blur", () => {
        inputs[i].classList.remove("input-focus");
        inputs[i].blur();
      });
    }
    let textAreas = document.getElementsByTagName("textarea");
    for (let i = 0; i < textAreas.length; i++) {
      textAreas[i].addEventListener("focus", () => {
        textAreas[i].classList.add("input-focus");
      });
      textAreas[i].addEventListener("blur", () => {
        textAreas[i].classList.remove("input-focus");
      });
    }
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      // add .submit-button class to button of type submit
      if (buttons[i].type === "submit") {
        buttons[i].classList.add("submit-button");
        buttons[i].addEventListener("focus", () => {
          buttons[i].classList.add("input-focus");
        });
        buttons[i].addEventListener("blur", () => {
          buttons[i].classList.remove("input-focus");
        });
      }
    }
  }
  submit(content: ContactForm): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var grecaptcha = (window as any).grecaptcha;
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6Lf6DbcfAAAAAB7s0J5oTa-SDih8US-BrEB0KvSl", {
          action: "submit",
        })
        .then(function (token) {
          // token check on POST https://www.google.com/recaptcha/api/siteverify
          fetch("https://www.google.com/recaptcha/api/siteverify", {
            method: "POST",
            body: JSON.stringify({
              secret: "6Lf6DbcfAAAAAB7s0J5oTa-SDih8US-BrEB0KvSl",
              response: token,
            }),
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": "true",
            },
            mode: "no-cors",
          }).then((response) => {
            console.log(response);

            // save form in firestore if user ip address is not already in database
            const contactRefs = db.collection(db.getFirestore(), "contact");
            if (!content.tel) {
              alert("Veuillez entrer un numéro de téléphone");
            } else if (!content.email) {
              alert("Veuillez entrer un email");
            } else if (!content.message) {
              alert("Veuillez entrer un message");
            } else if (!checkEmailIsAnEmail(content.email)) {
              alert("Veuillez entrer un email valide");
            } else if (!checkPhoneNumberIsAPhoneNumber(content.tel)) {
              alert("Veuillez entrer un numéro de téléphone valide");
            } else {
              const queryMail = db.query(
                contactRefs,
                db.where("email", "==", content.email)
              );

              const queryTel = db.query(
                contactRefs,
                db.where("tel", "==", content.tel)
              );
              const querySnapshotMail = db.getDocs(queryMail);
              const querySnapshotTel = db.getDocs(queryTel);

              querySnapshotMail.then((snapshot) => {
                // if user ip address is not already in database
                if (snapshot.empty) {
                  querySnapshotTel.then((snapshot) => {
                    if (snapshot.empty) {
                      let form = {
                        email: content.email,
                        message: content.message,
                        tel: content.tel,
                      } as ContactForm;

                      db.setDoc(
                        db.doc(db.getFirestore(), "contact", token),
                        form
                      );
                      alert("Message posté avec succès");
                    } else {
                      alert(
                        "Vous avez déjà envoyé un message avec ce tel. Merci de votre intérêt ! Je vais vous recontacter dans les plus brefs délais."
                      );
                    }
                  });
                } else {
                  alert(
                    "Vous avez déjà envoyé un message avec cet email. Merci de votre intérêt ! Je vais vous recontacter dans les plus brefs délais."
                  );
                }
              });
            }
          });
          // send data to firebase
          // remove focus and outlines on buttons
          let buttons = document.getElementsByTagName("button");
          for (let i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove("input-focus");
            buttons[i].blur();
          }
        });
    });
  }
}
function checkPhoneNumberIsAPhoneNumber(phoneNumber: string): boolean {
  let regex = /^\d{10}$/;
  return regex.test(phoneNumber);
}
function checkEmailIsAnEmail(email: string): boolean {
  // eslint-disable-next-line no-useless-escape
  let regex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}
